// extracted by mini-css-extract-plugin
export var column = "ProductSpecs__column__xpLV2";
export var headingMargin = "ProductSpecs__headingMargin__nxurO";
export var row = "ProductSpecs__row__OVbZW";
export var spec = "ProductSpecs__spec__nc0jF";
export var specsContainer = "ProductSpecs__specsContainer__fRFN4";
export var tableCell = "ProductSpecs__tableCell__dYo7Q";
export var tableContainer = "ProductSpecs__tableContainer__ayT0L";
export var tableRow = "ProductSpecs__tableRow__U9eAc";
export var tableRowDark = "ProductSpecs__tableRowDark__BFqjY";
export var value = "ProductSpecs__value__n7N4A";