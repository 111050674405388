// extracted by mini-css-extract-plugin
export var column = "ProductDescription__column__tBG4z";
export var featureDescription = "ProductDescription__featureDescription___wo28";
export var featureFlexContainer = "ProductDescription__featureFlexContainer__oTtl7";
export var featureMargin = "ProductDescription__featureMargin___mHHc";
export var featureTitle = "ProductDescription__featureTitle__NgtHR";
export var features = "ProductDescription__features__jdTx3";
export var productDescription = "ProductDescription__productDescription__ohhF3";
export var productDescriptionContainer = "ProductDescription__productDescriptionContainer__XkMMG";
export var productTitle = "ProductDescription__productTitle__bYcYi";
export var row = "ProductDescription__row__jhzH1";